const BASE_URL = `assets/team`

export const Team = {
  Danilo: {
    1: `${BASE_URL}/danilo_a.jpg`,
    2: `${BASE_URL}/danilo_b.jpg`,
  },
  Evair: {
    1: `${BASE_URL}/evair_a.jpg`,
    2: `${BASE_URL}/evair_b.jpg`,
  },
  Fernando: {
    1: `${BASE_URL}/fernando_a.jpg`,
    2: `${BASE_URL}/fernando_b.jpg`,
  },
  Filipe: {
    1: `${BASE_URL}/filipe_a.jpg`,
    2: `${BASE_URL}/filipe_b.jpg`,
  },
  Gabriel: {
    1: `${BASE_URL}/gabriel_a.jpg`,
    2: `${BASE_URL}/gabriel_b.jpg`,
  },
  Gini: {
    1: `${BASE_URL}/gini_a.jpg`,
    2: `${BASE_URL}/gini_b.jpg`,
  },
  Jose: {
    1: `${BASE_URL}/jose_a.jpg`,
    2: `${BASE_URL}/jose_b.jpg`,
  },
  Kenzo: {
    1: `${BASE_URL}/kenzo_a.jpg`,
    2: `${BASE_URL}/kenzo_b.jpg`,
  },
  Leo: {
    1: `${BASE_URL}/leo_a.jpg`,
    2: `${BASE_URL}/leo_b.jpg`,
  },
  'luiz-felipe': {
    1: `${BASE_URL}/luiz_felipe_a.jpg`,
    2: `${BASE_URL}/luiz_felipe_b.jpg`,
  },
  Matheus: {
    1: `${BASE_URL}/matheus_a.jpg`,
    2: `${BASE_URL}/matheus_b.jpg`,
  },
  Paulo: {
    1: `${BASE_URL}/paulo_a.jpg`,
    2: `${BASE_URL}/paulo_b.jpg`,
  },
  Sarah: {
    1: `${BASE_URL}/sarah_a.jpg`,
    2: `${BASE_URL}/sarah_b.jpg`,
  },
  Takuya: {
    1: `${BASE_URL}/takuya_a.jpg`,
    2: `${BASE_URL}/takuya_b.jpg`,
  },
  Victor: {
    1: `${BASE_URL}/victor_a.png`,
    2: `${BASE_URL}/victor_b.png`,
    3: `${BASE_URL}/victor.jpg`,
  },
  Vini: {
    1: `${BASE_URL}/vini_a.jpg`,
    2: `${BASE_URL}/vini_b.jpg`,
  },
  Vivian: {
    1: `${BASE_URL}/vivian_a.png`,
    2: `${BASE_URL}/vivian_b.png`,
    3: `${BASE_URL}/vivian.jpg`,
  },
  Yumi: {
    1: `${BASE_URL}/yumi_a.jpg`,
    2: `${BASE_URL}/yumi_b.jpg`,
  },
}
